<template>
  <!-- 密码弹框 -->
  <el-dialog v-model="dialogPwdVisible" title="修改密码" @close="closeFn">
    <el-form
      ref="pwdForm"
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item label="请输入原密码" prop="pass">
        <el-input
          v-model.trim="ruleForm.pass"
          type="password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="请输入新密码" prop="newPass">
        <el-input
          v-model.trim="ruleForm.newPass"
          type="password"
          autocomplete="off"
          :placeholder="iptPlaceholder"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="checkPass">
        <el-input
          v-model.trim="ruleForm.checkPass"
          type="password"
          autocomplete="off"
          :placeholder="iptPlaceholder"
        ></el-input>
      </el-form-item>
      <el-form-item class="last-item">
        <el-button size="small" class="first-btn" @click="closeFn"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="submitForm"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { reactive, toRefs, ref, nextTick } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import { ElMessage } from "element-plus";
import { changeSso } from "@/api/user";
import Cookies from "js-cookie";

export default {
  props: {
    dialogPwdVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    closePwd: null,
  },
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      iptPlaceholder: "请设置8-20位字符",
      ruleForm: {
        pass: "",
        checkPass: "",
        newPass: "",
      },
      rules: {
        // 密码校验规则
        pass: [
          { required: true, message: "原密码不能为空", trigger: "blur" },
          { min: 6, max: 20, message: "密码长度不正确", trigger: "blur" },
        ],
        checkPass: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value !== state.ruleForm.newPass) {
                callback(new Error("两次输入密码不一致"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        newPass: [
          {
            required: true,
            message:
              "密码需8~20位字符（请在英文状态下输入），需同时包含字母和数字，字母区分大小写",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value === state.ruleForm.pass) {
                callback(new Error("新密码不能与原密码相同"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
          {
            min: 8,
            max: 20,
            message: "密码长度需要在6到20位",
            trigger: "blur",
          },
          {
            pattern:/^(?=.*[0-9].*)(?=.*[A-Za-z].*)/,
            message:"新密码不满足复杂度要求，应输入8~20位字符（请在英文状态下输入），需同时包含字母和数字，字母区分大小写",
            trigger: "blur",

          }
        ],
      },
    });

    // 提交修改
    const pwdForm = ref(null);
    const submitForm = () => {
      pwdForm.value.validate(async (isOK) => {
        if (isOK) {
          try {
            const { data: res } = await changeSso({
              acct: store.state.loginInfo.userInfo.account,
              opwd: state.ruleForm.pass,
              npwd: state.ruleForm.newPass,
            });
            if (res.code == 0) {
              ElMessage.success("密码修改成功, 请重新登录");
              store.commit("removeSsoToken");
              store.commit("removeLoginInfo");
              if (Object.keys(Cookies.get()).length) {
                for (let k in Cookies.get()) {
                  Cookies.remove(k);
                }
              }
              router.push("/login");
            } else {
              ElMessage.error(res.message);
            }
          } catch (error) {
            console.log(error);
          }

          // console.log("前台校验通过发起请求");
          nextTick(() => pwdForm.value.resetFields());
          emit("closePwd");
        }
      });
    };
    // 取消->关闭弹框
    const closeFn = () => {
      nextTick(() => pwdForm.value.resetFields());
      emit("closePwd");
    };
    return {
      ...toRefs(state),
      submitForm,
      closeFn,
      pwdForm,
    };
  },
};
</script>
<style lang="less">
// 修改密码弹框
.el-dialog {
  width: 600px !important;
  box-shadow: 0px 2px 6px 1px rgba(255, 255, 255, 0.5);
}
.el-input {
  width: 320px;
  padding-bottom: 15px;

  .el-input__inner {
    height: 42px;
  }
}

.el-form-item__error {
  line-height: 1;
  position: absolute;
  top: 80%;
  left: 65px;
  width: 320px;
  // background-color: pink;
  text-align: start;
}

.last-item {
  margin-top: 45px;
  margin-bottom: 5px;
  .el-button {
    width: 120px;
    height: 60px;
    line-height: 1;
  }
  .first-btn {
    margin-right: 30px;
  }
}
</style>